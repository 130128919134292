<template>
  <div v-if="isMapShow">
    <GmapMap v-if="markers.length > 0 && page == 'orderstatus'" :center="center" :zoom="15"
      style="height: 320px !important">
      <GmapMarker :key="index" v-for="(m, index) in markers" :position="m" :clickable="true" :draggable="false"
        :icon="{ url: require('../assets/images/' + m.label) }" />
      <DirectionsRenderer travelMode="DRIVING" :origin=markers[0] :destination=markers[1] />
    </GmapMap>
    <GmapMap v-if="page == 'addaddress'" :center="center" :zoom="18" map-style-id="roadmap" :options="options"
      style="width: 100%; height: 300px" ref="mapRef" @click="handleMapClick">
      <GmapMarker :position="markers" :clickable="true" :draggable="true" @drag="handleMapClick" @click="panToMarker" />
    </GmapMap>
  </div>
</template>
<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import DirectionsRenderer from "./DirectionsRenderer.vue";
export default {
  name: "googlemap",
  props: ['page', 'markers', 'center', 'options'],
  components: { DirectionsRenderer },
  data() {
    return {
      isMapShow: false,
    }
  },

  mounted() {
    setTimeout(() => {
      window.google_map_key = this.$auth.setting.google_map_key;
      Vue.use(VueGoogleMaps, {
        load: {
          key: window.google_map_key, //config.google_key,
          libraries: "places",
        },
        installComponents: true,
      }).$gmapApiPromiseLazy().then(() => {
        window.google_map_key;
        this.isMapShow = true;
        this.panToMarker();

      });
    }, 500);

  },
  methods: {
    handleMapClick(e) {
      this.markers.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.$emit("onHandleClick", e);
    },

    // panToMarker(e) {
    //   this.$refs.mapRef.panTo(this.markers.position);
    // },


    panToMarker(e) {
      if (this.$refs.mapRef && this.markers && this.markers.position) {
        this.$refs.mapRef.panTo(this.markers.position);
      } else {
        console.error('Map reference or markers are not properly initialized.');
      }
    },



  }
};
</script>